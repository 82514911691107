<template>
    <div class="hotel-setup__table-content" v-if="floors.length">
        <table class="table">
            <thead class="table__table-head">
                <th>Floor</th>
                <th>Total Areas</th>
                <th>Total Rooms</th>
                <th>Options</th>
            </thead>
            <tbody>
                <tr v-for="floor in floors" :key="floor.id">
                    <td>{{ floor.floor_name }} {{ floor.floorPrefix }}</td>
                    <td>{{ floor.total_areas }} Areas</td>
                    <td>
                        {{ floor.total_rooms }} Rooms
                    </td>
                    <td>
                        <div class="table__options">
                            <router-link title="Edit Floor" :to="`/hotel_setup/floors/edit/${floor.id}`">
                                <img src="@/assets/icons/vuesax/linear/edit.svg" width="15" height="15" />
                            </router-link>
                            <a href="#" title="Delete Floor" @click.prevent="showModal(floor.id)">
                                <img src="@/assets/icons/vuesax/linear/trash.svg" width="15" height="15" />
                            </a>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <!-- Empty table records component -->
    <TableEmptyData title="Manage and configure hotel floors"
        message="It seems like the floors haven't been set up yet. In order to make the most of the application, it's important to configure the floors accordingly."
        v-if="!floors.length" />

    <!-- Table paginator component -->
    <TablePaginator ref="paginator" :api-url="floorsListApi" :filter="filterWords" v-model="floors" />

    <!-- Modal delete component -->
    <ModalDelete ref="modalDelete" @on-delete="refreshList" />
</template>

<script setup>
import { ref, onMounted } from 'vue'
import ModalDelete from '@/common/ModalDelete.vue'
import TablePaginator from '@/common/TablePaginator.vue'
import TableEmptyData from '@/common/TableEmptyData.vue'

defineProps(['filterWords'])

const modalDelete = ref(null)
const floorsListApi = `/web/floors`
const paginator = ref(null)
let floors = ref([])

function showModal(floorId) {
    const deleteFloorApi = `/web/floors/delete/${floorId}`
    modalDelete.value.showModal(deleteFloorApi)
}

function refreshList() {
    paginator.value.refreshPage()
}
</script>