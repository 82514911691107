<template>
    <div class="hotel-setup">
        <div class="hotel-setup__content hotel-setup--large-content">
            <img src="@/assets/images/prosafe-new-logo.svg" class="hotel-setup__logo" alt="">

            <div class="hotel-setup__info">
                Manage and have control of your hotel floors.
            </div>

            <div class="hotel-setup__options">
                <div class="hotel-setup__option-left">
                    <router-link class="button button--link button--text-medium" to="/hotel_setup">
                        <img src="@/assets/icons/ic_twotone-arrow-back-ios-new.svg" width="15" height="15" alt="">
                        Hotel Setup
                    </router-link>
                </div>
                <div class="hotel-setup__option-right">
                    <router-link class="button button--bordered button--small-padding" to="/hotel_setup/floors/add">
                        <img src="@/assets/icons/vuesax/linear/add-circle.svg" width="15" height="15" alt="">
                        Add Floor
                    </router-link>
                </div>
            </div>

            <div class="hotel-setup__card-content hotel-setup--card-padding">
                <div class="table-title">
                    <h1 class="table-title__title">Hotel Floors</h1>

                    <!-- Filter input component -->
                    <InputFilter v-model="words" />
                </div>

                <!-- Table component -->
                <FloorsTable :filterWords="words" />
            </div>
        </div>
    </div>
</template>

<script setup>
import InputFilter from '@/common/InputFilter.vue'
import FloorsTable from '../Components/FloorsTable.vue'
import { ref } from 'vue'

let words = ref("");
</script>